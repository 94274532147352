import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeComponent from '../views/home-page.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeComponent,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about-page.vue"),
  },
  {
    path: "/experiences",
    name: "Experiences",
    component: () =>
      import(
        "../views/experience-page.vue"
      ),
  },
  { path: "/:pathMatch(.*)*", name: "not-found", component: HomeComponent },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.resolve({
  name: "not-found",
  params: { pathMatch: ["not", "found"] },
}).href; // '/not/found'

export default router;
