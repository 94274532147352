import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router/router";
import store from "./store";
import "./main.scss";


// import '@/assets/scss/style.scss'
// import CoolLightBox from 'vue-cool-lightbox';
// import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

createApp(App).use(store).use(router).mount("#app");


