
import { Options, Vue } from "vue-class-component";
import NavLink from "@/components/NavLink.vue"; // @ is an alias to /src
import MainLayout from "@/layouts/mainLayout.component.vue"; // @ is an alias to /src

@Options({
  components: {
    NavLink,
    MainLayout
  },
})
export default class App extends Vue {


}
