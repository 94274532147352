
import { Options, Vue } from "vue-class-component";
import MenuItem from "./components/menuItem.component.vue";
import avatar from "@/assets/img/svg/avatar.svg";
import home from "@/assets/img/svg/home-run.svg";

@Options({
  components: {
    MenuItem,
  },
})
export default class MainLayout extends Vue {
  avatar = avatar;
  home = home;
}
