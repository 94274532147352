import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a731d19"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "menu_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", null, [
    _createVNode(_component_router_link, {
      to: _ctx.link,
      class: "link link--kale magic-hover magic-hover__square"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          class: "svg",
          src: _ctx.icon,
          alt: _ctx.text
        }, null, 8, _hoisted_1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}