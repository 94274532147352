<template>
  <div class="theme_a_home">
    <div class="home_content">
      <div class="avatar">
        <div
          class="image avatar_img"
          :style="{ backgroundImage: 'url(' + src1 + ')' }"
        ></div>
      </div>
      <div class="details">
        <div class="title">
          <h1>
            Ahmed Allam <span style="color:var(--mainClr); -webkit-text-stroke: 1px var(--mainClr);">.</span>
            <div class="title__highlight"></div>
          </h1>
          <div class="title__underline"></div>
          <div aria-hidden class="title__filled">
            Ahmed Allam <span style="color: var(--mainClr)">.</span>
          </div>
        </div>
        <div style="margin: 1rem 0; padding: 10px;">
          <span class="job">
            Experienced Software Developer with the latest cutting edge development tools
            and procedures.
          </span>
          <p class="job">
            Able to effectively self-manage during independent projects, as well as
            collaborate in a team setting.
          </p>
        </div>
        <SocialLinksComponent />
      </div>
    </div>
  </div>
</template>

<script>
import SocialLinksComponent from "./socialLinks-component.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    SocialLinksComponent,
  },
})
export default class HomeComponent extends Vue {
  data() {
    return {
      src1: require("../assets/img/slider/1.jpg"),
    };
  }
}
</script>
<!-- font-family: "Catamaran", sans-serif; -->
<!-- font-family: 'Oleo Script Swash Caps', cursive; -->
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Catamaran:900&display=swap");
.title {
  font-family: "Catamaran", sans-serif;
  font-family: "Oleo Script Swash Caps", cursive !important;
  color: black;
  cursor: pointer;
  position: relative;
  display: inline-block;

  h1 {
    text-align: left;
    font-family: "Catamaran", sans-serif;
    font-family: "Oleo Script Swash Caps", cursive !important;
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-transform: none;
    font-size: 3rem;
    padding-left: 1rem;
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    @media screen and (max-width: 1200px){
      padding-left: 49px;
    }
  }

  &__underline {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0em;
    height: 3px;
    background-color: #efcf17;
    z-index: 5;
    transition: transform 580ms cubic-bezier(0.2, 0.1, 0.15, 1.32);
  }

  &__highlight {
    position: absolute;
    width: 100%;
    left: 0;
    height: 50px;
    background-color: #ebebeb;
    z-index: -1;
    transition: transform 400ms ease;
    transform: translateY(-1.8rem);


  }

  &__filled {
    position: absolute;
    margin: 0;
    padding: 0;
    text-transform: none;
    font-size: 3rem;
    top: 0;
    /* bottom: 1.5rem; */
    padding-left: 1rem;
    display: block;

    // fill from up to down 
    clip-path: polygon(0% 100%, 0% 99%, 100% 99%, 100% 100%);
    

    // reverse fill from down to up
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);

    transition: clip-path 600ms cubic-bezier(0.2, 0.1, 0.15, 1.32);
    @media screen and (max-width: 1200px){
      padding-left: 49px;
    }
  }

  &:hover {
    .title__filled {
      // fill from up to down 
      clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 100% 100%);

      // reverse fill from down to up
      clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);

    }
    .title__underline {
      transform: translateY(-4.5rem);
    }
    .title__highlight {
      transform: translateY(-2.8rem);
      

    }
  }
}

.theme_a_home {
  width: 100%;
  min-height: 100vh;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.theme_a_home .home_content {
  display: flex;
  align-items: center;
  @media screen and (max-width: 1200px){
    flex-direction: column;
  }
}
.theme_a_home .avatar {
  min-width: 500px;
  min-height: 500px;
  position: relative;
  border-radius: 100%;
  @media screen and (max-width: 1200px){
    min-width: 380px;
    min-height: 380px;
  }
}
.theme_a_home .avatar .image {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: morph 8s ease-in-out infinite 1s;
  background-blend-mode: multiply;
  box-shadow: inset 0px 0px 0px 9px rgba(255, 255, 255, 0.3);
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  overflow: hidden;
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

.theme_a_home .details {
  margin-left: 80px;
  @media screen and (max-width: 1200px){
    margin-left: 0px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px !important;
  }
}
.theme_a_home .details .name {
  font-size: 55px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 19px;
}
.theme_a_home .details .job {
  //font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  max-width: 450px;
  //  margin-bottom: 30px;
}
</style>
