
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    icon: String,
    link: String,
    style: String,
    text: String,
  },
})
export default class MenuItem extends Vue {
    icon!: string;
    link!: string;
    style!: string;
    text!: string;
}
